<template>
    <ODataLookup :data-object="dsShutdowns" :whereClause="getWhereClause"
        reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
        </template>
        <OColumn field="ID" width="100"></OColumn>
        <OColumn field="Name" width="400"></OColumn>
        <OColumn field="OrgUnit" width="250"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { ODataLookup } from 'o365-datalookup';

const props = defineProps({
    textInput: Boolean,
    is: String,
    IdPath: String,
    textInputValue: String,
    OrgUnitId: Number
});

const dsShutdowns = $getDataObjectById("dsScope_ShutdownsLookup")

const restrictedToContext = ref(true);

const getWhereClause = computed(() => {
    var vFilter = [];

    if (props.OrgUnitId && restrictedToContext.value) {
        vFilter.push("ParentOrgUnit_ID = " + props.OrgUnitId);
    }

    if(props.IdPath && restrictedToContext.value){
        vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
    }

    if (!props.OrgUnitId && !props.IdPath) {
        vFilter.push("1=2");
    }

    return vFilter.join(" AND ");
});

</script>
